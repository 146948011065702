<template>
  <div>
    <p>
      
    </p>
    <div :class="`mb-10 ${$vuetify.breakpoint.xs?'':'indent'}`">
      <h1 class="text-center" style="position:relative">
        Support Plans
        <v-btn-toggle v-model="currency" mandatory class="multiline-btns" style="position:absolute;right:0;top:-45px;" >
          <v-btn v-for="cur in currencies" :key="cur" :value="cur" small>
            {{cur}}
          </v-btn>
        </v-btn-toggle>
      </h1>
      <div :class="{'align-center': true, 'indent':!$vuetify.breakpoint.xs }">
        <p>Select the support plan that works best for you.</p>
        <p>For first time customers or complex setups we advise to select the Setup-Service or Full-Service plan.</p>
      </div>
    </div> 

      <div ref="planSection" class="scroll-section lighten-4">
      <h3 class="pt-8 mb-0 pb-0 text-center">Support plans</h3>
      <v-row class="mb-8 mx-0 pricing-details ">
        <v-col cols="12" class="price-component-container">
          <v-sheet elevation="1" :class="{ 'price-component grey lighten-4' : true }">
            <h3 class="text-center">Self-Service</h3>
            <p>Do the setup yourself. Our knowledge base will guide you.</p>
            <hr/>
            <span class="large">Free</span>
            <!-- <v-btn outlined color="green" to="/eventmanager/create?license=BASIC">Start Now</v-btn> -->
            <hr />
            <ul class="check-list">
              <li>Knowledge Base</li>
              <li>Email support (1-2 business days)</li>
            </ul>
            
          </v-sheet>
          <v-sheet elevation="1" :class="{ 'price-component grey lighten-4' : true }">
            <h3 class="text-center">Setup-Service</h3>
            <p>We'll do the setup and a training session to get you up-and-running in no time!</p>
            <hr/>
            <span class="small mr-1">{{currency}}</span><span class="large">{{getProductPrice('product-setup-service') | int}}</span>
            <hr />
            <div class="align-left">All from <strong>Self-Service</strong>, plus:</div>
            <ul class="check-list">
              <li>We do setup and configuration of the event</li>
              <li>Training call</li>
              <li>Priority Setup Support</li>
              <li>Pay By Invoice</li>
              <li>Optional: import Employee Excel file</li>
            </ul>
          </v-sheet>
          <v-sheet elevation="1" :class="{ 'price-component grey lighten-4' : true }">
            <h3 class="text-center">Full-Service</h3>
            <p>We've got your back! Additional sessions for larger organizations.</p>
            <hr/>
            <span class="small mr-1">{{currency}}</span><span class="large">{{getProductPrice('product-full-service') | int}}</span>
            <hr />
            <div class="align-left">All from <strong>Setup-Service</strong>, plus:</div>
            <ul class="check-list">
              <li>Priority User Support</li>
              <li>Custom Virtual Map</li>
              <li>Two additional calls (for instance for review, go-live, or wrap-up calls)</li>
            </ul>
          </v-sheet>
        </v-col>
        <v-col class="align-center">
          <!-- <v-btn v-show="showPlanDetails" text color="primary" @click="showPlanDetails = false">Hide plan features</v-btn>
          <v-btn v-show="!showPlanDetails" text color="primary" @click="showPlanDetails = true">Show plan features</v-btn> -->
          <v-btn outlined rounded color="primary" to="/pricing" exact>Show all pricing information</v-btn>
        </v-col>
      </v-row>    
      </div>

    <div class="secondary white--text py-6 py-md-12 mt-4">
      <div class="container text-center">
        <v-row>
          <v-col cols="12" md="12">
            <h1 class="subtitle">Schedule a call to discuss pricing for <u>your</u> corporate fitness event</h1>
            <p class="mb-0 mt-4">
              <v-btn class="mb-4 mr-4" color="white" to="/request-demo">Request Demo</v-btn>
              <v-btn class="mb-4 mr-4" color="white" outlined to="/features">Explore features</v-btn>
            </p>
          </v-col>
        </v-row>
      </div>
    </div>


  </div>
</template>


<script>
import SectionIntegrations from "@/components/SectionIntegrations.vue";
import SectionCustomers from "@/components/SectionCustomers.vue";
import BigButton from "@/components/BigButton.vue";
import SimpleTooltip from "@/components/SimpleTooltip.vue";
import PriceCalculatorDialog from "@/components/PriceCalculatorDialog.vue";
import siteData from '@/data/site.json'
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "SupportPlans",
  components: {
    SectionIntegrations,
    SectionCustomers,
    BigButton,
    SimpleTooltip,
  },
  props: {
  },
  data: function() {
    return {
      siteData: siteData,
      tenant: tenant,
      products: siteData.products,
      currency: 'USD',
      currencies: siteData.currencies,
    };
  },
  mounted() {
  },
  created () {
  },
  unmounted () {
  },
  methods: {
    getProductPrice(productKey) {
      var product = this.products.find(x=>x.key==productKey)
      if (!product) {
        return null;
      }
      return product.prices[this.currency];
    },
    
  },
  computed: {
  }
};
</script>

<style lang="scss" scoped>
  .small { font-size: 75%; display: inline-block; text-align: left;}
  .large { font-size: 200%;}
  .large + .small { margin-left: 5px; }

  .pricing-details {
    h3 { margin: 20px 0; }
    .v-btn { margin: 20px 0; }
    ul { text-align: left;;}
    .popular { border: solid 5px #00B46E !important; }
    .plan {
      position: relative;
      border: solid 5px #ccc;
      cursor: pointer;

    }
    hr { margin: 10px 0; border-top-width: 0; border-bottom-color: #00000055;}
  }
  .feature-table {
    tr {
      th { text-align: center; }
      td { 
        text-align: center;
        padding: 8px 4px; 
        border-bottom: solid #fff 1px;
      }
      td h4 { margin-top: 8px;}
      td:first-child { padding-left: 16px; text-align: left; }
      td:last-child { padding-right: 16px; }
      th:nth-child(3), td:nth-child(3) { background-color: white; }
    }

  }

  .price-summary {
    a.select-option { color: #777; text-decoration: underline; text-decoration-style: dotted; }
    .features {
      .v-icon {min-width: 20px;}
    }
  }
  .price-side-bar {
    position: fixed;
    bottom: 8px;
    right: 8px;
    z-index: 7;
    max-width: 200px;
    font-size: 90%;
  }
  
  .price-component-container {
    display: flex;
    justify-content: center;
    align-items: stretch !important;
    flex-wrap: wrap;
    gap: 20px;
    font-size: 90%;
    .price-component {
      padding: 12px;
      max-width: 200px;
      text-align: center;
      cursor: pointer;
      position: relative;
      border-radius: 4px;

      h3 { margin-top: 16px; margin-bottom: 8px;}
      .v-icon { margin-bottom: 8px; font-size: 1em; }
    }
    .most-popular { position: absolute; top: -16px; left: 50%; transform: translate(-50%, 0); }

    .selected-marker {
      display: none;
      position: absolute;
      right: 2px;
      top: 2px;
      ///background-color: white;
      //transform:rotate(-45deg);
      &::before {
        display: block;
        content: '';
        width:0;
        height:0;
        border-top:15px solid #fff;
        border-left:15px solid transparent;
        border-right:15px solid transparent;
      }
      &::after {
        display: block;
        content: '';
        width:0;
        height:0;
        border-top: 15px solid transparent;
        border-bottom: 15px solid transparent;
        border-right: 15px solid #fff;
        margin-left: 15px;
        margin-top: -37px;
      }
      .v-icon { position: relative; top: -15px; right: -8px;}
    }
    .selected{
      background-color: var(--v-primary-lighten2) !important;
      .selected-marker { display: block; }
    }
  }
  @media (max-width: 959px) {
    .v-application .price-summary-bottom {
      position: fixed;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 7;
      font-size: 90%;
      border-top: 2px solid var(--v-accent-base) !important  ;
      .section-title { padding: 0; vertical-align: middle; }
      p { margin-bottom: 0;}
      .features .v-icon { display: none;}
    }
  }

  .scroll-section { scroll-margin-top: 50px; }

  .v-sheet.dotted, button.dotted { border: 2px dotted #ccc; border-radius: 4px; background: white; }
  button.dotted { border: 1px dotted var(--v-primary-base); }
  
  .faqs {
    h3 { margin-top: 1.5em; }
  }
  .indent { margin: 0 5%; }

</style>